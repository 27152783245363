<template>
  <section class="footer-main">
    <div class="footer-main__header">
      <div>
        <RouterLink class="footer-main__header__brand" :to="{ name: 'home' }">
          <img src="/images/logo_golstats_horizontal_white.svg" alt="GolStats" />
        </RouterLink>
      </div>
    </div>
    <div class="footer-main__content">
      <div class="footer-main__content__section-hidden"></div>
      <div class="footer-main__content__section-1">
        <div class="title_section">Our sections</div>
        <div class="item_section" @click="onClickProfessional">Professional Soccer</div>
        <div class="item_section" @click="onClickAmateur">Amateur</div>
        <div class="item_section" @click="onClickAmbassadors">GolStats Ambassadors</div>
        <div class="item_section" @click="onClickMedia">Media</div>
        <div class="item_section" @click="onClickSports">Sports Company</div>
        <div class="item_section" @click="onClickTechnology">Technology Partners</div>
      </div>
      <div class="footer-main__content__section-2">
        <div class="title_section">About us</div>
        <div class="item_section" @click="onClickTerms">Terms of Service</div>
        <div class="item_section" @click="onClickPrivacy">Privacy Policy</div>
        <div class="item_section" @click="onClickCookie">Cookie Use</div>
      </div>
      <div class="footer-main__content__section-3">
        <div class="title_section">GolStats Users</div>
        <div class="item_section" @click="onClickCreate">Create New User</div>
        <div v-if="!isLoggedIn" class="item_section" @click="onClickLogin">Login</div>
      </div>
      <div class="footer-main__content__section-4">
        <div class="title_section">Need Help?</div>
        <div class="item_section" @click="onClickContact">Contact Us</div>
      </div>
      <div class="footer-main__content__section-hidden"></div>
    </div>
    <div class="footer-main__footer">© Copyright – GolStats S.A.P.I. de C.V.</div>
  </section>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex';
export default {
  name: 'Footer',
  props: {},
  computed: {
    ...mapGetters('loginGeneral', ['isLoggedIn']),
  },
  methods: {
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    onClickProfessional() {
      window.open('https://info.golstats.com/#professional');
    },
    onClickAmateur() {
      window.open('https://debuta.mx/');
    },
    onClickAmbassadors() {
      window.open('https://info.golstats.com/#ambassadors');
    },
    onClickMedia() {
      window.open('https://info.golstats.com/#media');
    },
    onClickSports() {
      window.open('https://info.golstats.com/#sports');
    },
    onClickTechnology() {
      window.open('https://info.golstats.com/#partners');
    },
    onClickTerms() {
      window.open('https://info.golstats.com/terms');
    },
    onClickPrivacy() {
      window.open('https://info.golstats.com/policies');
    },
    onClickCookie() {
      window.open('https://info.golstats.com/cookies');
    },
    onClickCreate() {
      this.SET_AUTH_COMPONENT('selectPlan');
    },
    onClickLogin() {
      this.SET_AUTH_COMPONENT('LoginMain');
    },
    onClickContact() {
      window.open('/contact-main');
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-main {
  margin-top: 60px;
  &__header {
    margin-top: 0px;
    height: 152px;
    background-color: #000;
    position: relative;
    border-bottom: 2px solid #6eb90f;

    &__brand {
      position: absolute;
      top: 42px;
      left: 50%;
      & img {
        max-height: 4rem;
        position: relative;
        left: -50% !important;
      }
    }
  }
  &__content {
    margin-top: 0px;
    height: 307px;
    background-color: #212121;
    position: relative;
    @media screen and (max-width: 650px) {
      height: 700px;
    }
  }
  &__footer {
    margin-top: 0px;
    height: 80px;
    line-height: 80px;
    background-color: #212121;
    position: relative;
    text-align: center;
    font-size: 13px;
    color: white;
    font-family: 'Avenir-Pro-Medium';
  }
  &__content {
    display: grid;
    grid-template-columns: 14% 18% 18% 18% 18% 14%;
    grid-template-rows: min-content auto;
    font-family: 'Avenir-Pro-Medium';
    text-align: left;
    @media screen and (max-width: 1500px) {
      grid-template-columns: 10% 20% 20% 20% 20% 10%;
    }
    @media screen and (max-width: 1360px) {
      grid-template-columns: 6% 22% 22% 22% 22% 6%;
    }
    @media screen and (max-width: 650px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      &__section-1 {
        margin-left: 10%;
      }
      &__section-2 {
        margin-left: 10%;
      }
      &__section-3 {
        margin-left: 10%;
      }
      &__section-4 {
        margin-left: 10%;
      }
    }
  }
}
.title_section {
  font-family: 'Avenir-Pro-Bold';
  font-size: 1.75rem;
  color: white;
  margin-top: 42px;
  @media screen and (max-width: 1000px) {
    font-size: 1.4rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: 650px) {
    font-size: 1.6rem;
  }
}
.item_section {
  color: #d2d2d2;
  margin-top: 6px;
  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media screen and (max-width: 650px) {
    font-size: 16px;
  }
}
.item_section:hover {
  color: white;
  cursor: pointer;
}
</style>
